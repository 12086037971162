import { useContext } from "react";
import CookieContext from "../contexts/CookieContext";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
  const { cookies } = useContext(CookieContext);
  const jwtAccess = cookies.jwtAccess;
  // console.log("Private route works!");

  // return jwtAccess ? <Outlet /> : <Navigate to="/login" />;
  return jwtAccess ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
