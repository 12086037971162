import { createContext, useState, useEffect } from "react";
import axios from "axios";

const SyukujitsuContext = createContext({});

export const SyukujitsuContextProvider = ({ children }) => {
  const [syukujitsu, setSyukujitsu] = useState([]);

  useEffect(() => {
    const initFetch = async () => {
      const response = await axios.get(
        `https://hook.btime.online/master/Syukujitsu/`
      );
      setSyukujitsu(response.data);
      console.log("response.data(syukujitsu):", response.data);
    };
    initFetch();
  }, []);

  return (
    <SyukujitsuContext.Provider
      value={{
        syukujitsu,
      }}
    >
      {children}
    </SyukujitsuContext.Provider>
  );
};

export default SyukujitsuContext;
