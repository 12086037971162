// import "./App.css";
import Account from "./Account";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

const Layout = () => {
  return (
    <>
      <div className="bd-callout bd-callout-info">
        <Account />
      </div>
      <div className="bd-callout bd-callout-info">
        <Navbar />
      </div>
      <div className="bd-callout bd-callout-info">
      <Outlet />
      </div>
      <div className="bd-callout bd-callout-info">
        <Footer />
      </div>
    </>
  );
};

export default Layout;
