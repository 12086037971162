const Contact = () => {
    return (
      <div className="ui raised very padded text container segment"
      style={{marginTop: '0px'}}
      >
        <h3 className="ui header">Contact</h3>
        <p>Contact</p>
      </div>
    );
  };
  export default Contact;
  