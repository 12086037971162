import { NavLink } from 'react-router-dom'
import { useContext, useState } from "react";

import styled from "@emotion/styled";
import NavbarContext from '../contexts/NavbarContext';

const ButtonToggle = styled.button`
background-color: white;
color: #3B71CA;
border-color: #3B71CA;
font-size: 1rem;
padding: 10px 20px;
width: 180px;
height: 50px;
${({ active }) => active && `background-color: #3B71CA; color: white`}
`;

const Navbar = () => {

  // const navs = ['Home', 'Syukujitsu', 'About', 'Contact', 'Count', 'CountLogin', 'MyPage', ];
  // const navsJ = ['ホーム', '祝日', 'about', '連絡', 'カウント', 'カウントログイン', 'マイページ', ];
  // const [active, setActive] = useState(navs[0]);
  const { navs, navsJ, active, setActive, } = useContext(NavbarContext);

  return (
    <>
      {navs.map((nav, index) => {
        return (
          <span key={index}>
            <NavLink to={nav}>
              <ButtonToggle key={nav}
                active={active === nav}
                onClick={() => setActive(nav)}
              >{navsJ[index]}</ButtonToggle>
            </NavLink>
          </span>
        )}
      )}
    </>
  )
}

export default Navbar
