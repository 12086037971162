import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
// contextsより
import { AppContextProvider } from "./contexts/AppContext";
import { CookieContextProvider } from "./contexts/CookieContext";
import { NavbarContextProvider } from "./contexts/NavbarContext";
import { ExpTimeContextProvider } from "./contexts/ExpTimeContext";
import { SyukujitsuContextProvider } from "./contexts/SyukujitsuContext";

import Layout from "./components/Layout";
import MyPage from "./components/MyPage";
import Home from "./components/Home";
import Count from "./components/Count";
import CountLogin from "./components/CountLogin";
import Syukujitsu from "./components/Syukujitsu";
import Products from "./components/Products";
import CompTimer from "./components/CompTimer";
import About from "./components/About";
import Contact from "./components/Contact";

function App() {
  return (
    <AppContextProvider>
      <div className="App">
        <CookieContextProvider>
          <NavbarContextProvider>
            <ExpTimeContextProvider>
              <BrowserRouter>
                <SyukujitsuContextProvider>
                  <Routes>
                    <Route path="/" element={<Layout />}>
                      <Route path="/Home" element={<Home />} />
                      <Route path="/About" element={<About />} />
                      <Route path="/Contact" element={<Contact />} />
                      <Route path="/Syukujitsu" element={<Syukujitsu />} />
                      <Route path="/Count" element={<Count />} />
                      <Route path="/CompSyukujitsu" element={<Syukujitsu />} />
                      <Route element={<PrivateRoutes />}>
                        <Route path="/MyPage" element={<MyPage />} />
                        <Route path="/CountLogin" element={<CountLogin />} />
                        <Route path="/Products" element={<Products />} />
                        <Route path="/CompTimer" element={<CompTimer />} />
                      </Route>
                    </Route>
                  </Routes>
                </SyukujitsuContextProvider>
              </BrowserRouter>
            </ExpTimeContextProvider>
          </NavbarContextProvider>
        </CookieContextProvider>
      </div>
    </AppContextProvider>
  );
}

export default App;
