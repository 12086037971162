import { createContext } from "react";

const ExpTimeContext = createContext();

export const ExpTimeContextProvider = ({ children }) => {
  // （期限切れ時刻が取得できない場合、又は現在時刻を過ぎている場合）：false、（期限切れ時刻が現在時刻を過ぎていない場合）：true
  const expCheck = (ExpiredSecond) => {
    if (
      isNaN(ExpiredSecond) ||
      Math.floor(ExpiredSecond - Date.now() / 1000) < 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  // 期限切れ時刻をExpiredとすると、それが現在時刻から何秒（s）後かを計算
  const leftTime = (ExpiredSecond) => {
    if (expCheck(ExpiredSecond)) {
      return Math.floor(ExpiredSecond - Date.now() / 1000);
    } else {
      return "-";
    }
  };

  // 期限切れ時刻をExpiredとすると、それが現在時刻から何分（M）何秒（s）後かを計算
  const leftTimeMs = (ExpiredSecond) => {
    if (expCheck(ExpiredSecond)) {
      return (
        seconds2minMs(Math.floor(ExpiredSecond - Date.now() / 1000)) +
        ":" +
        seconds2sec(Math.floor(ExpiredSecond - Date.now() / 1000))
      );
    } else {
      return "-";
    }
  };

  // 期限切れ時刻をExpiredとすると、それが現在時刻から何日（D）何時間（h）何分（m）何秒（s）後かを計算
  const leftTimeDhms = (ExpiredSecond) => {
    if (expCheck(ExpiredSecond)) {
      return (
        seconds2day(Math.floor(ExpiredSecond - Date.now() / 1000)) +
        "day " +
        seconds2hour(Math.floor(ExpiredSecond - Date.now() / 1000)) +
        ":" +
        seconds2min(Math.floor(ExpiredSecond - Date.now() / 1000)) +
        ":" +
        seconds2sec(Math.floor(ExpiredSecond - Date.now() / 1000))
      );
    } else {
      return "-";
    }
  };
  const seconds2day = (seconds) => {
    let day = Math.floor(seconds / (3600 * 24));
    day = String(day);
    return day;
  };
  const seconds2hour = (seconds) => {
    let hour = Math.floor(seconds / 3600) % 24;
    hour = String(hour);
    return hour.padStart(2, "0");
  };
  const seconds2min = (seconds) => {
    let min = Math.floor(seconds / 60) % 60;
    min = String(min);
    return min.padStart(2, "0");
  };
  const seconds2sec = (seconds) => {
    let sec = seconds % 60;
    sec = String(sec);
    return sec.padStart(2, "0");
  };
  const seconds2minMs = (seconds) => {
    let min = Math.floor(seconds / 60);
    min = String(min);
    return min.padStart(2, "0");
  };

  return (
    <ExpTimeContext.Provider
      value={{
        leftTime,
        leftTimeMs,
        leftTimeDhms,
      }}
    >
      {children}
    </ExpTimeContext.Provider>
  );
};

export default ExpTimeContext;
