import { useContext, useState } from "react";
import AppContext from "../contexts/AppContext";
// import Timer from "./Timer";
import { format } from "date-fns";

const CompTimer = () => {
  const { accessTokenExp } = useContext(AppContext);

  const [display, setDisplay] = useState(true);

  const curDT = new Date();
  const formatDT =
    curDT.getFullYear() +
    "-" +
    (curDT.getMonth() + 1) +
    "-" +
    curDT.getDate() +
    " " +
    curDT.getHours() +
    ":" +
    curDT.getMinutes() +
    ":" +
    curDT.getSeconds();
  const curMilliseconds = curDT.getTime();

  // const curTime = new Date();
  const curTime = format(new Date(curDT), "yyyy/MM/dd HH:mm:ss");

  return (
    <div>
      <button onClick={() => setDisplay(!display)}>Switch Timer</button>
      {/* {display && <Timer />} */}
      <br />
      {formatDT}
      <br />
      CUR:{Math.ceil(curMilliseconds / 1000)}:{curTime}
      <br />
      aacccc:{accessTokenExp}
    </div>
  );
};

export default CompTimer;
