import { useContext, useEffect } from "react";
import CookieContext from "../contexts/CookieContext";

const Count = () => {
  const { setCookie, count, dispatchCount } = useContext(CookieContext);

  useEffect(() => {
    // set cookie
    setCookie("count", count);
  }, [count]);

  return (
    <div>
      <button onClick={() => dispatchCount("add_1")}>add_1</button>
      <button onClick={() => dispatchCount("multiple_3")}>multiple_3</button>
      <button onClick={() => dispatchCount("minus_10")}>minus_10</button>
      <button onClick={() => dispatchCount("reset")}>reset</button>
      <br />
      {count}
    </div>
  );
};

export default Count;
