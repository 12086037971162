import { createContext, useReducer } from "react";
import { useCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { format } from "date-fns";

const CookieContext = createContext();

const countReducer = (currentState, action) => {
  switch (action) {
    case "add_1":
      console.log("add_1");
      return currentState + 1;
    case "add_100":
      console.log("add_100");
      return currentState + 100;
    case "minus_10":
      return currentState - 10;
    case "multiple_3":
      return currentState * 3;
    case "multiple_4":
      return currentState * 4;
    case "divide_2":
      return currentState / 2;
    case "reset":
      return 1;
    default:
      return currentState;
  }
};

let initialCount = 1;

export const CookieContextProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  let now = new Date();

  // count（cookie）がNaNの場合は1、NaN以外の場合はcount（cookie）を数値型に変換してinitialCountに代入
  {
    // isNaN(cookies.get("count"))
    isNaN(cookies.count)
      ? (initialCount = 1)
      : (initialCount = Number(cookies.count));
  }

  const [count, dispatchCount] = useReducer(countReducer, initialCount);
  // const [appMessage, setAppMessage] = useState("");
  // const [alertMessage, setAlertMessage] = useState("alert:-");

  const setJwtCookies = (jwtRefresh, jwtAccess) => {
    setCookie("jwtRefresh", jwtRefresh);
    setCookie("jwtAccess", jwtAccess);

    console.log("[setCookieFromjwt](jwtRefresh):%s", jwtRefresh);
    console.log("[setCookieFromjwt](jwtAccess):%s", jwtAccess);

    // set cookie [jwtRefreshVerifySignature, jwtAccessVerifySignature]
    setCookie("jwtRefreshVerifySignature", jwtRefresh.substr(-43));
    setCookie("jwtAccessVerifySignature", jwtAccess.substr(-43));

    // set cookie [refreshTokenExp, refreshTokenExpTime, refreshTokenIat, refreshTokneIatTime,
    // refreshTokenJti, refreshTokenUserId, refreshTokenName]
    setCookie("refreshTokenExp", jwtDecode(jwtRefresh).exp);
    setCookie(
      "refreshTokenExpTime",
      format(new Date(jwtDecode(jwtRefresh).exp * 1000), "yyyy/MM/dd HH:mm:ss")
    );
    setCookie("refreshTokenIat", jwtDecode(jwtRefresh).iat);
    setCookie(
      "refreshTokenIatTime",
      format(new Date(jwtDecode(jwtRefresh).iat * 1000), "yyyy/MM/dd HH:mm:ss")
    );
    setCookie("refreshTokenJti", jwtDecode(jwtRefresh).jti);
    setCookie("refreshTokenUserId", jwtDecode(jwtRefresh).user_id);
    setCookie("refreshTokenUuid", jwtDecode(jwtRefresh).uuid);
    setCookie("refreshTokenUsername", jwtDecode(jwtRefresh).username);

    // set cookie [accessTokenExp, accessTokenExpTime, accessTokenIat, accessTokneIatTime,
    // accessTokenJti, accessTokenUserId, accessTokenName]
    setCookie("accessTokenExp", jwtDecode(jwtAccess).exp);
    setCookie(
      "accessTokenExpTime",
      format(new Date(jwtDecode(jwtAccess).exp * 1000), "yyyy/MM/dd HH:mm:ss")
    );
    setCookie("accessTokenIat", jwtDecode(jwtAccess).iat);
    setCookie(
      "accessTokenIatTime",
      format(new Date(jwtDecode(jwtAccess).iat * 1000), "yyyy/MM/dd HH:mm:ss")
    );
    setCookie("accessTokenJti", jwtDecode(jwtAccess).jti);
    setCookie("accessTokenUserId", jwtDecode(jwtAccess).user_id);
    setCookie("accessTokenUuid", jwtDecode(jwtAccess).uuid);
    setCookie("accessTokenUsername", jwtDecode(jwtAccess).username);

    // ログイン成功時、画面遷移前のres、res.statusをチェック
    // debugger;
  };

  const removeJwtCookies = () => {
    removeCookie("jwtRefresh");
    removeCookie("jwtAccess");
    removeCookie("jwtRefreshVerifySignature");
    removeCookie("jwtAccessVerifySignature");
    removeCookie("refreshTokenExp");
    removeCookie("refreshTokenExpTime");
    removeCookie("refreshTokenIat");
    removeCookie("refreshTokenIatTime");
    removeCookie("refreshTokenJti");
    removeCookie("refreshTokenUserId");
    removeCookie("refreshTokenUuid");
    removeCookie("refreshTokenUsername");
    removeCookie("accessTokenExp");
    removeCookie("accessTokenExpTime");
    removeCookie("accessTokenIat");
    removeCookie("accessTokenIatTime");
    removeCookie("accessTokenJti");
    removeCookie("accessTokenUserId");
    removeCookie("accessTokenUuid");
    removeCookie("accessTokenUsername");
  };

  return (
    <CookieContext.Provider
      value={{
        cookies,
        setCookie,
        removeCookie,
        setJwtCookies,
        removeJwtCookies,
        count,
        dispatchCount,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};

export default CookieContext;
