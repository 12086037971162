import "./Account.css";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { AppRegistration, Login, Logout, Refresh } from "@mui/icons-material";
import { motion, useAnimationControls } from "framer-motion";

import AppContext from "../contexts/AppContext";
import { format } from "date-fns";
import CookieContext from "../contexts/CookieContext";
import NavbarContext from "../contexts/NavbarContext";
import JwtContext from "../contexts/ExpTimeContext";
import axios from "axios";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";

const Account = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const { apiUserHost } = useContext(AppContext);
  const [registerView, setRegisterView] = useState(false);

  const [jwtCheck, setJwtCheck] = useState(false);

  const { cookies, setJwtCookies, removeJwtCookies } =
    useContext(CookieContext);

  const { navs, navsJ, active, setActive } = useContext(NavbarContext);

  const alertAnimationControls = useAnimationControls();

  const [axiosErr, setAxiosErr] = useState({ code: "", msg: "" });
  const { leftTime, leftTimeMs, leftTimeDhms } = useContext(JwtContext);

  // dayOfWeek[] 0:日曜日、1:月曜日、2:火曜日、3:水曜日、4:木曜日、5:金曜日、6:土曜日
  const dayOfWeek = Object.freeze({
    0: "日",
    1: "月",
    2: "火",
    3: "水",
    4: "木",
    5: "金",
    6: "土",
  });

  // Date().getDay() 0:日曜日、1:月曜日、2:火曜日、3:水曜日、4:木曜日、5:金曜日、6:土曜日
  const day = new Date().getDay();
  // youbi:和名
  const youbi = dayOfWeek[day];
  const [clock, setClock] = useState("0000/00/00(　) 00:00:00");
  const [disabled, setDisabled] = useState(false);

  const time = () => {
    // 出力フォーマット
    const curTime = format(Date.now(), "yyyy/MM/dd(" + youbi + ") HH:mm:ss");
    setClock(curTime);

    if (leftTime(cookies.refreshTokenExp) > 0) {
    } else if (leftTime(cookies.refreshTokenExp) === "-") {
      // cookieを削除し、ログイン画面を表示
      removeJwtCookies();
    } else {
    }
  };

  let ClockClassName = "ClockNormal";

  if (day === 0) {
    // 日曜日は赤色表示
    ClockClassName = "ClockSun";
  } else if (day === 6) {
    // 土曜日は青色表示
    ClockClassName = "ClockSat";
  } else {
    // 平日は色変化なし
  }

  const loginHandler = async () => {
    await setAxiosErr({ code: "", msg: "" });
    await setRegisterView(false);
  };

  const registerHandler = async () => {
    await setAxiosErr({ code: "", msg: "" });
    await setRegisterView(true);
  };

  const onLogin = async (data) => {
    setDisabled(true);
    console.log("data", data);
    console.log("username", data.username);
    console.log("password", data.password);

    await axios
      .post(
        // `https://api.btime.online/user/token/`,
        `${apiUserHost}user/token/`,
        {
          username: data.username,
          password: data.password,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(async (res) => {
        setDisabled(false);
        // set cookie [jwtRefresh, jwtAccess]
        await setJwtCookies(res.data.refresh, res.data.access);

        // ログイン後はMyPageへ遷移
        await (window.location.href = "/MyPage");
        // await setActive("MyPage");
      })
      .catch((err) => {
        setDisabled(false);
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("err.response:", err.response);
          console.log("err.response.status:", err.response.status);
          console.log("err.response.data.detail:", err.response.data.detail);

          setAxiosErr({
            ...axiosErr,
            code: err.response.status,
            msg: "ユーザ名またはパスワードが違います",
          });
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("err.request:", err.request);

          setAxiosErr({
            ...axiosErr,
            code: "",
            msg: "エラーが発生しました",
          });
        } else {
          // anything else
          console.log("err:", err);

          setAxiosErr({
            ...axiosErr,
            code: "",
            msg: "エラーが発生しました",
          });
        }
        // debugger;
      });
  };

  const onRegister = async (data) => {
    setDisabled(true);
    console.log("data", data);
    console.log("username", data.username);
    console.log("password", data.password);

    await axios
      .post(
        // `https://privateapi.btime.online/apiuser/token/`,
        // `https://api.btime.online/user/token/`,
        `${apiUserHost}user/register/`,
        {
          username: data.username,
          password: data.password,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(async (res) => {
        setDisabled(false);

        // refresh
        await alert("登録が完了しました");
        window.location.href = "/";
      })
      .catch((err) => {
        setDisabled(false);
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("err.response:", err.response);

          setAxiosErr({
            ...axiosErr,
            code: err.response.status,
            msg: "そのユーザ名は登録済です",
          });
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("err.request:", err.request);

          setAxiosErr({
            ...axiosErr,
            code: "",
            msg: "request時にエラーが発生しました",
          });
        } else {
          // anything else
          console.log("err:", err);

          setAxiosErr({
            ...axiosErr,
            code: "",
            msg: "エラーが発生しました",
          });
        }
      });
  };

  const refreshJwtCookies = async () => {
    setDisabled(true);

    const response = await axios
      // await axios
      .post(
        // `https://api.btime.online/user/token/refresh/`,
        `${apiUserHost}user/token/refresh/`,
        {
          refresh: cookies.jwtRefresh,
        }
      )
      .then(async (res) => {
        setDisabled(false);
        console.log("res.status:", res.status);

        // set cookie [jwtRefresh, jwtAccess]
        await setJwtCookies(res.data.refresh, res.data.access);

        console.log("cookies.jwtRefresh:", cookies.jwtRefresh);
        console.log("res.data.refresh:", res.data.refresh);
      })
      .catch((err) => {
        setDisabled(false);
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("err.response:", err.response);
          // variableCheckProvided("err.response", err.response);
          console.log("err.response.status:", err.response.status);
          console.log("err.response.data.detail:", err.response.data.detail);

          // エラーの場合、cookieを削除して、トップ画面へ遷移
          removeJwtCookies();

          setAxiosErr({
            ...axiosErr,
            code: err.response.status,
            msg: "有効なRefreshTokenではありません",
          });
          window.location.href = "/";
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("err.request:", err.request);

          // エラーの場合、cookieを削除して、トップ画面へ遷移
          removeJwtCookies();

          setAxiosErr({
            ...axiosErr,
            code: "",
            msg: "エラーが発生しました",
          });
          window.location.href = "/";
        } else {
          // anything else
          console.log("err:", err);

          // エラーの場合、cookieを削除して、トップ画面へ遷移
          removeJwtCookies();

          setAxiosErr({
            ...axiosErr,
            code: "",
            msg: "エラーが発生しました",
          });
          window.location.href = "/";
        }
      });
  };

  useEffect(() => {
    const interval = setInterval(time, 200);
    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    alertAnimationControls.start({
      background: [
        "#fff",
        "#ff0",
        "#fff",
        "#ff0",
        "#fff",
        "#ff0",
        "#fff",
        "#ff0",
        "#fff",
        "#ff0",
      ],
      transition: {
        duration: 6.0,
      },
    });
  }, [axiosErr]);

  if (cookies.jwtAccess) {
    return (
      <>
        <div className={ClockClassName} style={{ margin: "5px" }}>
          {clock}
        </div>
        {/* accessTokenExp, accessTokenExpTime, accessTokenIat,
        accessTokneIatTime, accessTokenJti,
        accessTokenUuid, accessTokenUsername, accessTokenUserId */}
        <div style={{ margin: "5px" }}>
          {cookies.accessTokenUsername}&nbsp;
          {/* uuidを表示する場合 */}
          {/* {cookies.accessTokenUuid.slice(0, 4)} */}
          <Button
            disabled={disabled}
            variant="contained"
            endIcon={<Logout />}
            onClick={removeJwtCookies}
          >
            <span className="material-symbols-outlined">ログアウト</span>
          </Button>
        </div>
        <div style={{ margin: "5px" }}>
          {leftTime(cookies.refreshTokenExp) < 2592000 ? (
            // 60 * 60 * 24 * 30（日）＝2,592,000
            <Button
              disabled={disabled}
              variant="contained"
              color="warning"
              endIcon={<Refresh />}
              onClick={refreshJwtCookies}
            >
              <span className="material-symbols-outlined">
                ログイン継続（{leftTimeDhms(cookies.refreshTokenExp)}）
              </span>
            </Button>
          ) : (
            // "認証期限：" + cookies.refreshTokenExpTime
            ""
          )}
        </div>
      </>
    );
  } else {
    if (registerView === false) {
      return (
        <>
          <div className={ClockClassName} style={{ margin: "5px" }}>
            {clock}
          </div>
          <div>
            <form onSubmit={handleSubmit(onLogin)}>
              <motion.div className="ErrMsg" animate={alertAnimationControls}>
                <p className="ErrMsg">{axiosErr.msg}</p>
              </motion.div>
              <label htmlFor="username">ユーザ名</label>
              <input
                type="text"
                id="username"
                {...register("username", {
                  required: "ユーザ名は必須です",
                  minLength: {
                    value: 4,
                    message: "4-16文字で入力してください",
                  },
                  maxLength: {
                    value: 16,
                    message: "4-16文字で入力してください",
                  },
                })}
              />
              <p className="ErrMsg">{errors.username?.message}</p>
              <label htmlFor="password">パスワード</label>
              <input
                type="password"
                id="password"
                autoComplete="off"
                {...register("password", {
                  required: "パスワードは必須です",
                  minLength: {
                    value: 4,
                    message: "4-16文字で入力してください",
                  },
                  maxLength: {
                    value: 16,
                    message: "4-16文字で入力してください",
                  },
                })}
              />
              <p className="ErrMsg">{errors.password?.message}</p>
              <Button
                type="submit"
                disabled={disabled}
                variant="contained"
                endIcon={<Login />}
              >
                <span>ログイン</span>
              </Button>
            </form>
            <Button onClick={registerHandler}>新規登録はこちら</Button>
          </div>
        </>
      );
    } else if (registerView === true) {
      return (
        <>
          <div className={ClockClassName} style={{ margin: "5px" }}>
            {clock}
          </div>
          <div>
            <form onSubmit={handleSubmit(onRegister)}>
              <motion.div className="ErrMsg" animate={alertAnimationControls}>
                <p className="ErrMsg">{axiosErr.msg}</p>
              </motion.div>
              <label htmlFor="username">ユーザ名</label>
              <input
                type="text"
                name="username"
                id="username"
                {...register("username", {
                  required: "ユーザ名は必須です",
                  minLength: {
                    value: 4,
                    message: "4-16文字で入力してください",
                  },
                  maxLength: {
                    value: 16,
                    message: "4-16文字で入力してください",
                  },
                })}
              />
              <font color="#ef810f">（日本語可）</font>
              <p className="ErrMsg">{errors.username?.message}</p>
              <label htmlFor="password">パスワード</label>
              <input
                type="password"
                name="password"
                id="password"
                {...register("password", {
                  required: "パスワードは必須です",
                  minLength: {
                    value: 4,
                    message: "4-16文字で入力してください",
                  },
                  maxLength: {
                    value: 16,
                    message: "4-16文字で入力してください",
                  },
                })}
              />
              <p className="ErrMsg">{errors.password?.message}</p>
              <Button
                type="submit"
                disabled={disabled}
                variant="contained"
                color="warning"
                endIcon={<AppRegistration />}
              >
                <span>新規登録</span>
              </Button>
            </form>

            <Button onClick={loginHandler}>ログインはこちら</Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>Error</div>
        </>
      );
    }
  }
};

export default Account;
