import { useContext } from "react";
import SyukujitsuContext from "../contexts/SyukujitsuContext";

const CompSyukujitsu = () => {
  const { syukujitsu } = useContext(SyukujitsuContext);

  return (
    <div>
      {syukujitsu?.map((data, i) => (
        <div key={i}>
          <p>
            {i}:{data?.nengappi}
            {data?.meisyou}
          </p>
        </div>
      ))}
    </div>
  );
};

export default CompSyukujitsu;
