import { createContext, useState } from "react";

const NavbarContext = createContext();

export const NavbarContextProvider = ({ children }) => {
  const navs = [
    "MyPage",
    "Home",
    "Syukujitsu",
    "About",
    "Contact",
    "Count",
    "CountLogin",
  ];
  const navsJ = [
    "マイページ",
    "ホーム",
    "祝日",
    "about",
    "連絡",
    "カウント",
    "カウントログイン",
  ];

  const [active, setActive] = useState(navs[0]);

  return (
    <NavbarContext.Provider
      value={{
        navs,
        navsJ,
        active,
        setActive,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};

export default NavbarContext;
