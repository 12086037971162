const About = () => {
  return (
    <div className="ui raised very padded text container segment"
    style={{marginTop: '0px'}}
    >
    <h3 className="ui header">About</h3>
      <p>About</p>
    </div>
  );
};
export default About;
