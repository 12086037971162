import { useContext, useEffect } from "react";
import CookieContext from "../contexts/CookieContext";

const CountLogin = () => {
  const { setCookie, count, dispatchCount } = useContext(CookieContext);

  useEffect(() => {
    // set cookie
    setCookie("count", count);
  }, [count]);

  return (
    <div>
      <button onClick={() => dispatchCount("add_100")}>add_100</button>
      <button onClick={() => dispatchCount("multiple_4")}>multiple_4</button>
      <button onClick={() => dispatchCount("divide_2")}>divide_2</button>
      <button onClick={() => dispatchCount("reset")}>reset</button>
      <br />
      {count}
    </div>
  );
};

export default CountLogin;
